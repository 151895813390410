<div class="col-12 col-xl-6 px-2 px-md-3 px-xl-0 mx-auto mt-4 mt-xl-4 text-center">
  <div *ngIf="file">
    <div>
      <img
        *ngIf="file.type === 'image'"
        src="{{ file.url }}"
      />
      <video
        *ngIf="file.type === 'video'"
        #videoPlayer
        playsinline
        controls
        crossorigin
      >
        <source
          [src]="file.url | safe"
          type="video/mp4"
          (error)="this.isError = true"
        />
      </video>

      <audio
        *ngIf="file.type === 'audio'"
        class="col-12 col-md-9 outline-none"
        #audio
        controlsList="nodownload"
        controls
        [hidden]="!file.url"
      >
        <source [src]="file.url" />
      </audio>
    </div>
    <a
      [href]="file.url"
      target="_blank"
    >
      {{ file.url }}
    </a>
  </div>
  <error-message [errorMessage]="errorMessage"></error-message>
</div>
