import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ManagementService } from "@app/management/management.service";
import { CommonModule } from "@angular/common";
import { ErrorMessageComponent } from "@app/shared/basic-components";
import { DndDirective } from "@app/shared/directives";

@Component({
  standalone: true,
  selector: "upload-audio",
  templateUrl: "./upload-audio.component.html",
  styleUrls: ["./upload-audio.component.scss"],
  imports: [CommonModule, ErrorMessageComponent, DndDirective],
})
export class UploadAudioComponent implements OnInit {
  @ViewChild("audio", { static: true }) audio!: any;

  @Input() trainingId!: number;
  @Input() audioUrl!: string;

  @Output() audioUpload: EventEmitter<string> = new EventEmitter();

  file!: File;
  message!: string;
  isError!: boolean;
  isLoading!: boolean;
  audioElement!: HTMLAudioElement;
  MAX_SIZE = 1e7;
  fileTypes = ["audio/mpeg", "audio/wav", "audio/ogg"];

  constructor(private managementService: ManagementService) {}

  ngOnInit(): void {
    this.audioElement = this.audio.nativeElement;
  }

  onFileDropped(event: any) {
    this.checkFiles(event);
  }

  checkFiles(files: FileList) {
    if (files.length > 1) {
      this.message = "Slechts één bestand is toegestaan";
    } else {
      const audioFile = files[0];
      this.isValidFile(audioFile);
    }
  }

  onAudioAdded(event: any) {
    const audioFile = event.target.files[0];
    this.isValidFile(audioFile);
  }

  isValidFile(audioFile: File) {
    if (this.validFileType(audioFile) && this.validFileSize(audioFile)) {
      this.message = "";
      this.file = audioFile;
      this.setFormData();
    } else if (!this.validFileType(audioFile)) {
      this.message = "Formaat is niet geldig";
    } else if (!this.validFileSize(audioFile)) {
      this.message = "Audio is groter dan 10MB";
    }
  }

  validFileType(audioFile: File) {
    return this.fileTypes.includes(audioFile.type);
  }

  validFileSize(audioFile: File) {
    return audioFile.size < this.MAX_SIZE;
  }

  setFormData() {
    this.isLoading = true;
    const formData: FormData = new FormData();
    formData.append("file", this.file, (this.file as File).name);
    this.uploadFile(formData);
  }

  uploadFile(formData: FormData) {
    this.managementService.uploadFile(formData, this.trainingId).subscribe({
      next: (createdAudio: any) => {
        this.cleanErrorAndMessage();
        this.audioUrl = createdAudio.file_url;
        this.setAudioSrc();
        this.audioUpload.emit(this.audioUrl);
      },
      error: () => {
        this.message = "Er ging iets fout";
        this.isError = true;
        this.isLoading = false;
      },
    });
  }

  removeAudio() {
    this.audioUpload.emit("");
    this.cleanFile();
  }

  cleanErrorAndMessage() {
    this.message = "";
    this.isLoading = false;
    this.isError = false;
  }

  cleanFile() {
    this.cleanErrorAndMessage();
  }

  onChangeAssignment() {
    this.cleanFile();
    this.setAudioSrc();
  }

  setAudioSrc() {
    this.audioElement.src = this.audioUrl;
  }
}
