<div
  class="container-input text-center my-3 fileover"
  appDnd
  (fileDropped)="onFileDropped($event)"
  *ngIf="!file && !audioUrl"
>
  <input
    class="cursor-pointer h-100 w-100"
    type="file"
    #fileDropRef
    id="fileDropRef"
    accept=".mp3, .wav, .opus"
    (change)="onAudioAdded($event)"
  />

  <div class="btn btn-primary d-block">Upload audio</div>
</div>

<div *ngIf="file">
  <div class="single-file justify-content-center d-flex mt-2 col-10 col-md-6 mx-auto">
    {{ file.name }}
  </div>
</div>

<div
  class="text-center mt-2"
  *ngIf="isLoading"
>
  <b>Laden...</b>
</div>

<error-message [errorMessage]="message"></error-message>
<div
  class="text-primary text-center"
  *ngIf="isError"
>
  Probeer het opnieuw
</div>

<div class="d-flex flex-wrap justify-content-center my-3 align-items-center">
  <audio
    #audio
    controls
    controlsList="nodownload"
    class="col-10 col-md-7 px-0 outline-none"
    [hidden]="!audioUrl"
  >
    <source [src]="audioUrl" />
  </audio>

  <div
    class="delete d-flex cursor-pointer flex-end ms-2"
    (click)="removeAudio()"
    *ngIf="audioUrl"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 14 18"
    >
      <path
        fill="#B1B1B1"
        fill-rule="nonzero"
        d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z"
      />
    </svg>
  </div>
</div>
