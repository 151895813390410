import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AttachmentService } from "@app/shared/services/attachment.service";
import { UtilService } from "@app/shared/services/util.service";

import { Attachment } from "@app/shared/models/entities/Attachment";
import { CommonModule } from "@angular/common";
import { TitleComponent } from "@app/shared/basic-components";
import { SafePipe } from "@app/shared/pipes";

@Component({
  standalone: true,
  selector: "shared-attachment-page",
  templateUrl: "./attachment-page.component.html",
  styleUrls: ["./attachment-page.component.scss"],
  imports: [CommonModule, TitleComponent, SafePipe],
})
export class AttachmentPageComponent implements OnInit {
  attachmentId!: number;
  attachment!: Attachment;

  constructor(
    private utilService: UtilService,
    private attachmentService: AttachmentService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getParams();
    this.getAttachment();
  }

  getParams() {
    this.attachmentId = +this.route.snapshot.params["attachment-id"];
  }

  getAttachment() {
    this.attachmentService.getAttachment(this.attachmentId).subscribe({
      next: (attachment) => {
        this.attachment = attachment;
        this.utilService.setTitle("Bijlage - " + this.attachment.title);
      },
    });
  }

  onPdfClicked(url: string) {
    window.open(url, "_blank");
  }
}
