import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ManagementService } from "@app/management/management.service";
import { CommonModule } from "@angular/common";
import { ErrorMessageComponent } from "@app/shared/basic-components";
import { DndDirective } from "@app/shared/directives";
import { SafePipe } from "@app/shared/pipes";

@Component({
  standalone: true,
  selector: "upload-video-component",
  templateUrl: "./upload-video.component.html",
  styleUrls: ["./upload-video.component.scss"],
  imports: [CommonModule, ErrorMessageComponent, DndDirective, SafePipe],
})
export class UploadVideoComponent {
  @Input() trainingId!: number;
  @Input() videoUrl!: string;

  @Output() videoUpload: EventEmitter<string> = new EventEmitter();
  @Output() videoRemove: EventEmitter<void> = new EventEmitter();

  message!: string;
  isError!: boolean;
  isLoading!: boolean;
  MAX_SIZE = 5e7;
  fileTypes = ["video/mp4"];

  constructor(private managementService: ManagementService) {}

  onFileDropped(event: any) {
    this.checkFiles(event);
  }

  checkFiles(files: FileList) {
    if (files.length > 1) {
      this.message = "Slechts één bestand is toegestaan";
    } else {
      const videoFile = files[0];
      this.isValidFile(videoFile);
    }
  }

  onVideoAdded(event: any) {
    const videoFile = event.target.files[0];
    this.isValidFile(videoFile);
  }

  isValidFile(videoFile: File) {
    if (this.validFileType(videoFile) && this.validFileSize(videoFile)) {
      this.message = "";
      this.setFormData(videoFile);
    } else if (!this.validFileType(videoFile)) {
      this.message = "Formaat is niet geldig";
    } else if (!this.validFileSize(videoFile)) {
      this.message = "Video is groter dan 50MB";
    }
  }

  validFileType(videoFile: File) {
    return this.fileTypes.includes(videoFile.type);
  }

  validFileSize(videoFile: File) {
    return videoFile.size < this.MAX_SIZE;
  }

  setFormData(file: File) {
    this.isLoading = true;
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    this.uploadFile(formData);
  }

  uploadFile(formData: FormData) {
    this.managementService.uploadFile(formData, this.trainingId).subscribe({
      next: (createdVideo: any) => {
        this.cleanErrorAndMessage();
        this.videoUrl = createdVideo.file_url;
        this.videoUpload.emit(this.videoUrl);
      },
      error: () => {
        this.message = "Er ging iets fout";
        this.isError = true;
        this.isLoading = false;
      },
    });
  }

  removeVideo() {
    this.videoRemove.emit();
    this.videoUrl = "";
    this.cleanErrorAndMessage();
  }

  cleanErrorAndMessage() {
    this.message = "";
    this.isLoading = false;
    this.isError = false;
  }
}
