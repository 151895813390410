<div *ngIf="attachment && attachment.files?.length > 0 && !hasOnlyImages">
  <div
    class="os-bold font-22 px-0 mb-2"
    *ngIf="attachment.files?.length > 1"
  >
    {{ attachment.title }}
  </div>
  <div
    *ngFor="let file of attachment.files; let i = index"
    [ngClass]="{ 'mb-3': i !== attachment.files?.length - 1 }"
  >
    <div
      *ngIf="file.type === 'audio'"
      class="audio-box"
    >
      <h5
        *ngIf="file.title"
        class="os-bold font-18 mb-2"
      >
        {{ file.title }}
      </h5>
      <audio
        class="col-12 col-md-9 outline-none"
        #audio
        controlsList="nodownload"
        controls
        [hidden]="!file.url"
      >
        <source [src]="file.url" />
      </audio>
    </div>
    <div
      *ngIf="file.type === 'video'"
      class="video-box"
    >
      <h5
        *ngIf="file.title"
        class="os-bold font-18 mb-2"
      >
        {{ file.title }}
      </h5>
      <video
        *ngIf="file.type === 'video'"
        class="col-12 col-md-9 outline-none"
        #video
        playsinline
        controls
        crossorigin
      >
        <source
          [src]="file.url | safe"
          type="video/mp4"
          (error)="this.isError = true"
        />
      </video>
    </div>

    <div
      *ngIf="file.type === 'image'"
      class="file-box"
    >
      <div
        class="os-link"
        (click)="onAttachmentClicked(attachment)"
      >
        {{ file.title }}
      </div>
    </div>

    <div
      *ngIf="file.type === 'pdf'"
      class="file-box"
    >
      <div
        class="os-link"
        (click)="onPdfClicked(file.url)"
      >
        {{ file.title }}
      </div>
    </div>
  </div>
</div>

<div *ngIf="hasOnlyImages">
  <div class="file-box">
    <div
      class="os-link"
      (click)="onAttachmentClicked(attachment)"
    >
      {{ attachment.title }}
    </div>
  </div>
</div>
