<div
  *ngIf="attachment.files?.length > 0"
  style="border-color: black !important; border-width: 1px !important"
  class="border-bottom pb-2 mt-3 mb-2"
  [ngClass]="{
    'border-top pt-3 mt-3': !isLanguageAttachment,
    'avoid-break': attachment.files?.length === 1,
  }"
>
  <b
    *ngIf="hasImages && !isLanguageAttachment"
    class="d-block mb-2"
  >
    {{ attachment.title }}
  </b>
  <div *ngIf="hasImages">
    <div
      *ngFor="let file of attachment.files; let i = index"
      class="mb-3"
      [ngClass]="isLanguageAttachment && i === 0 ? 'margin-right' : ''"
    >
      <div *ngIf="file.type === 'image'">
        <img
          [src]="file.url"
          [style.width]="file.width ? file.width + 'px' : 'auto'"
          class="max-image-size"
          [ngClass]="
            i === 0
              ? extraSmall
                ? attachment.title.length < 65
                  ? 'image-size-extra-small'
                  : 'image-size-extra-extra-small'
                : 'image-size-small'
              : 'image-size-full-page'
          "
        />
      </div>
      <div
        *ngIf="file.type === 'audio' || file.type === 'video' || file.type === 'pdf'"
        class="mb-4"
      >
        <qrcode
          [qrdata]="'https://portal.onlineslagen.nl/bestand/' + file.id"
          [width]="80"
        ></qrcode>
      </div>
    </div>
  </div>
  <div *ngIf="!hasImages">
    <div
      *ngFor="let file of attachment.files; let i = index"
      class="col-4 d-inline-block text-center"
    >
      <qrcode
        [qrdata]="'https://portal.onlineslagen.nl/bestand/' + file.id"
        [width]="80"
      ></qrcode>
      <div>{{ file.type }}</div>
    </div>
  </div>
</div>
